'use client';

import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import CryptoJS from 'crypto-js';
import Link from 'next/link';
import { setLoginNew } from '../services/auth';

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const router = useRouter();

  const onSubmit = async (event: any) => {
    event.preventDefault();

    const data = {
      email,
      password,
    };

    if (!email || !password) {
      toast.error('Email dan Password wajib diisi!!!');
    } else {
      const response = await setLoginNew(data);
      if (response.error) {
        console.log(response);
        toast.error(response.message);
      } else {
        try {
          var bytes = CryptoJS.AES.decrypt(
            response.data.token,
            'pos-fotohokkie'
          );
          var originalText = bytes.toString(CryptoJS.enc.Utf8);
          var decode: any = jwtDecode(originalText);
          console.log(originalText);
          const tokenBase64 = Buffer.from(originalText).toString('base64');
          Cookies.set('tkn', tokenBase64, { expires: 1 });
          // await setCookie('tkn', tokenBase64);
          console.log('check decode token', decode);
          // console.log('CHECK DATA TYPE');
          // console.log(decode.type);

          if (decode.roleId !== null) {
            toast.success('Login Berhasil');

            if (decode.roleId === 5) {
              router.push('/dashboard/customer');
            } else if (decode.roleId === 8) {
              router.push('/dashboard/rekap-absen');
            } else {
              console.log('data admin super');
              router.push('/dashboard');
            }
          } else {
            toast.error(
              'Akun tidak memiliki akses, silakan klik tombol "Absensi"'
            );
          }
        } catch (error) {
          console.error('Error dekripsi atau decoding token:', error);
          toast.error('Token tidak valid.');
          return;
        }
      }
    }
  };

  return (
    <div className='flex items-center justify-center h-screen px-6 bg-gray-200'>
      <div className='w-full max-w-sm p-6 bg-white rounded-md shadow-md'>
        <div className='flex items-center justify-center'>
          <span className='text-2xl font-semibold text-gray-700'>LOGIN</span>
        </div>

        <form onSubmit={onSubmit} className='mt-4'>
          <label className='block'>
            <span className='text-sm text-gray-700'>Email</span>
            <input
              type='text'
              className='block w-full px-2 py-2 mt-1 border border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500'
              placeholder='email'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
          </label>
          <label className='block mt-3'>
            <span className='text-sm text-gray-700'>Password</span>
            <input
              type='password'
              className='block w-full mt-1  px-2 py-2 border border-gray-200 rounded-md focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500'
              placeholder='password'
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
            />
          </label>

          <div className='mt-6 mb-2'>
            <button
              type='submit'
              // onClick={() => router.push('/dashboard')}
              className='w-full px-4 py-2 text-sm text-center text-white hover:text-primary bg-primary rounded-md focus:outline-none hover:bg-secondary'
            >
              SIGN IN
            </button>
          </div>
          <div className='flex justify-between'>
            <label className='block mt-3 relative'>
              <span className='text-sm text-gray-700'>Lupa Password? </span>
              <Link href='/reset-password' className='text-blue-500 text-sm'>
                Klik Disini
              </Link>
            </label>
            <label className='block mt-3 relative'>
              <Link
                href='/login-presence'
                className='text-primary border border-primary bg-white px-4 py-2 rounded-lg hover:shadow-md transition-shadow duration-300 ease-in-out'
              >
                Absensi
              </Link>
            </label>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
